@use "sass:math";

$icon-font-path: "../assets/fonts/";

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

$questionnaire-spinner-z-index: 100;
$questionnaire-spinner-size: 10vw;
$questionnaire-spinner-color: #b9b9b9;
$questionnaire-spinner-color-alt: #f3f3f3;
$questionnaire-spinner-speed: 1s;
$cep-icon-font-path: "assets/fonts/";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../vendor/reflex-cep-questionnaire2/scss/questionnaire/questionnaire.scss";
@import "questionnaire.scss";

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  overflow: auto;
}

app-root {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100vh;
}

strong {
  font-weight: bold;
}

.rsp-container {
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  .content {
    margin: 1em 0;
  }
}

.cookie-consent {
  align-items: center;
  background: #f3f3f3;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0.5em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;

  > {
    * {
      margin-top: 0.5em;
      padding: 0 0.5em;
    }

    p {
      margin-bottom: 0;
      text-indent: 3em;
    }
  }
}

.rsp-spinner-overlay {
  position: fixed;
	width: 100%;
	height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.rsp-spinner-wrapper {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.rsp-spinner {
  width: 75px;
  height: 75px;
  border-width: 10px;
  display: inline-block;
  border-color: $gray-200;
  border-style: solid;
  border-right-color: $gray-600;
  border-radius: 50%;

  margin-top: 40px;
  margin-left: 40px;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ms-1 {
  margin-right: 1em !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.flex-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.center-page {
  width: 50%;
  min-width: 20rem;
  align-self: center;
}

#authFrame {
  border: none;
  height: 35vh;
  overflow: hidden;
  width: 100%;
  min-width: 20rem;
  align-self: center;
  margin-bottom: 1em;
}

#signFrame {
  border: none;
  height: 70vh;
  overflow: hidden;
  width: 100%;
  min-width: 20rem;
  align-self: center;
  margin-bottom: 1em;
}

#review-form {
  margin: 2% 0;

  h1 {
    font-size: 0.875rem;
    margin-bottom: 0;
    background-color: #6e6e6e;
    color: white;
    padding: 0.5% 1%;
  }
}

.modal-content {
  border: 0;
}

.modal-header-container {
  width: 100%;
}

.modal-header-col-left {
  padding: 0;
}

.modal-header-col-right {
  padding: 0;
  text-align: right;
}

.btn {
  border-radius: 0.25rem;
}

.btn-secondary {
  color: black;
  background-color: #efefef;
  border-color: #efefef;
}

h1,
h2,
h3 {
  hyphens: auto;
}

// prevent "jiggle effect" when opening a Bootstrap-Modal
.modal-open {
  padding-right: 0px !important;
}

// ##### Header-Component #####

.header-bar {
  min-height: 3.375rem;
}

.header-logout-timer {
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}

.progress {
  padding: 0;
  border-radius: 0;
}

// ##### Footer-Component #####

.rsp-footer {
  flex-shrink: 0;

  .footer {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }

  #footer-phone-email dt {
    margin-right: 0.625rem;
  }
}

dl.grid {
  display: grid;
  grid-template-columns: 1fr 100%;
}

dd {
  margin-bottom: 0;
}

// ##### Consent-Component #####

app-consent {
  div {
    margin-top: 1em;

    > label {
      font-weight: normal;
      margin-left: 1em;
      vertical-align: top;
      width: 80%;
    }
  }

  .consent-information {
    border: 2px solid lightgrey;
    height: 50vh;
    overflow-y: auto;
    padding: 1em;

    > ol {
      list-style-position: inside;
      padding: 0;

      > li {
        font-weight: bold;

        * {
          font-weight: normal;
        }

        p:first-child {
          display: inline-block;
          font-weight: bold;
          margin-left: 2em;
        }
      }
    }
  }

  .consent-buttons {
    display: flex;
    justify-content: flex-end;
  }
}

// ##### initial-information-Component #####

app-initial-information {
  .initial-information-content {
    .toggle-create {
      width: 20em;
      margin-bottom: 1em;
      margin-top: 2em;
    }

    .product-list {
      margin-top: 1.25rem;
    }

    label {
      margin-left: 1em;
      margin-bottom: 0.5em;
    }

    .riders {
      margin-left: 2em;

      label {
        margin-left: 1em;
      }
    }

    .risk-assessment-panel {
      .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        border: 1px solid rgba(0,0,0,.125);
      }

      .well.product-info {
        margin-bottom: 1.25rem;
        background-color: #d9edf7;
      }

      .well.assessment-status {
        background-color: #dff0d8;
      }
    }

    .card:not(:last-child) {
      margin-bottom: 1em;
    }

    .card-header.panel-heading, .card-footer {
      padding: 0.75rem 1.25rem;
    }

    .card-body {
      padding: 1.25rem;
    }
  }
}

// ##### Confirm-Component #####

app-confirm {
  div {
    margin-top: 1em;

    > label {
      font-weight: normal;
      margin-left: 1em;
      vertical-align: top;
      width: 80%;
    }
  }

  .confirm-information {
    border: 2px solid lightgrey;
    height: 50vh;
    overflow-y: auto;
    padding: 1em;

    > ol {
      list-style-position: inside;
      padding: 0;

      > li {
        font-weight: bold;

        * {
          font-weight: normal;
        }

        p:first-child {
          display: inline-block;
          font-weight: bold;
          margin-left: 2em;
        }
      }
    }
  }
}

// ##### result-Component #####

app-result {
  #result .card {
    margin-bottom: 1.25rem;

    .card-header {
      padding: 0.75rem 1.25rem;
    }

    .card-title {
      font-weight: bold;
      margin-bottom: 0;
    }

    .well-sm {
      margin-top: 1em;
      padding: 0.5625rem;
      border-radius: 0;

      p {
        margin: 0;
      }

      .rating-details {
        margin-top: 1em;
      }
    }

    .decision-default {
      @extend .bg-info;
    }

    .list-attributes {
      list-style: square;
    }
  }
}
