$default-input-height: 2.125rem;

$color-purple-dark: #665c96;
$color-purple-light: #7e72b6;

$default-border-radius: 0.25rem;

.rsp-assessment.review-form {
  // margin: 2% 0;

  cep-web-questionnaire {
    .question-form .question-template {
      margin-bottom: 0;
    }

    h1 {
      font-size: 0.875rem;
      margin-bottom: 0;
      background-color: #6e6e6e;
      color: white;
      padding: 0.5% 1%;
    }

    .question-template,
    .question-label {
      margin: 0;
    }

    .list-unstyled {
      margin-bottom: 0;
    }

    .question-label-wrapper,
    .question-content-wrapper {
      background-color: transparent;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      margin-top: -1px;
      padding: 0.5% 1%;
      width: 50%;
    }

    .question-label-wrapper {
      border-left: 1px solid #ccc;
    }

    .question-content-wrapper {
      border-right: 1px solid #ccc;
    }

    .question-label-wrapper .question-index {
      color: black;
    }

    .question-annotation-wrapper {
      display: none;
    }

    .panel {
      margin: 0;

      .question-label-wrapper,
      .question-content-wrapper {
        background-color: transparent;
      }

      .panel-heading {
        background-color: #d4d4d4;
        color: black;
        padding: 0.5% 2%;
      }
    }

    .type-label.question-container {
      border: 1px solid #ccc;
      background-color: transparent;
      padding: 0.5% 1%;
    }

    // most commonly if the default font-size of the browser was not changed: max-width = 1024px
    @media only screen and (max-width: 64rem) {
      .question-form .question-content-wrapper,
      .question-form .question-label-wrapper {
        width: 100% !important;
        border: none !important;
        padding: 1.5% 2%;
      }

      .question-form .question-wrapper {
        border: 1px solid #ccc;
      }
    }

    cep2-question-assessment-factor-group {
      .question-group.type-assessment-factor-group {
        border: 1px solid #ccc;

        .card-header {
          background-color: #bebebe;
        }
      }

      cep2-assessment-factor-group-headline > h2.card-header {
        font-size: 0.875rem;
        margin-bottom: 0;
        background-color: #6e6e6e;
        color: white;
        padding: 0.5% 1%;

        .card-header-action {
          visibility: hidden;
        }
      }

      .card-controls.assessment-factor-group-controls {
        visibility: collapse;
      }

      .assessment-factor-group-controls > button.btn {
        visibility: hidden;
      }

      .card {
        border: none;
      }

      .card-body {
        padding: 0;

        .question-wrapper {
          width: 100%;
        }

        .card-controls {
          display: none;
        }
      }
    }
  }
}

.rsp-assessment {
  cep-web-questionnaire {
    // most commonly if the default font-size of the browser was not changed: max-width = 1024px
    @media only screen and (max-width: 64rem) {
      .question-form .short-input {
        width: 100%;
      }

      .question-form .type-search.form-group {
        height: auto;
      }

      .question-form .unicorn-search.unicorn-search--disabled {
        .unicorn-chip {
          margin-bottom: 0.125rem;

          &:before {
            content: "\00B7";
            margin-right: 0.125rem;
          }
        }
      }

      cep2-standard-unit-number-form-field .input-group {
        flex-wrap: nowrap;
      }
    }

    .question-form .unicorn-search {
      min-width: unset;
    }

    .question-form .unicorn-search.unicorn-search--disabled {
      min-width: unset;

      .unicorn-search__inner {
        line-height: unset;

        &.form-control {
          flex-direction: row;
          align-items: normal;
        }

        .unicorn-search__input {
          display: none;
        }
      }
    }

    // fix multi-select height
    select[multiple].form-control.short-input {
      height: 6.1875rem;
    }

    // Question group headers
    .question-form h1.smaller-headline {
      font-size: 1.5rem;
    }

    // Increase question template bottom margin from 1rem to 20px (1.25rem)
    .question-form .question-template {
      margin-bottom: 1.25rem;
    }

    // Unit buttons
    // replaces the removed .input-group-append from Bootstrap 4
    // keep input and button elements in the same line
    .input-group > *:last-child {
      @extend .input-group-addon;
      height: $default-input-height !important;
      background-color: #eee;
    }

    // Remove calendar button icon padding
    cep2-form-field[data-question-type="date"] {
      .input-group > *:last-child {
        padding: 0;
      }

      .btn-secondary:hover {
        color: #fff;
        background-color: rgb(90, 98, 104);
        border-color: rgb(84, 91, 98);
      }
    }

    cep2-assessment-factor-group-headline > .card-header > a {
      color: white;
    }

    cep2-question-assessment-factor-group {
      .assessment-factor-group-controls > button.btn {
        color: rgb(52, 52, 52);
        background-color: #f5f5f5;
      }
    }

    cep2-question-assessment-factor-group
      .question-group.type-assessment-factor-group {
      border: 1px solid $color-purple-dark;

      .card-header {
        margin-top: 0px;
        padding: 1rem 0.9375rem;
        color: white;
        font-size: 0.9375rem;
        font-weight: 700;
        background-color: $color-purple-dark;
      }

      .card-body {
        padding-top: 0.625rem;
        padding-left: 0.9375rem;
      }
    }

    .question-form {
      .question-reminder {
        padding: 0.625rem;
        top: 1.75rem;
        right: 1.375rem;
        border: 1px solid $color-purple-dark;
        border-radius: 10px;

        &.minimized {
          top: 4.75rem;
        }

        .arrow.hide-if-minimized {
          display: none;
        }

        .question-reminder-message {
          margin-bottom: 0.3125rem;
        }
      }

      // Date time picker (hours / minutes)
      .type-date .date-time-picker-component {
        // Correct icon background when time (hours / minutes) is invalid
        .input-group > .input-group-text:last-child {
          background-color: transparent;
        }

        // Correct the looks of the hours / minutes input fields
        .timepicker .form-group {
          padding: 0.125rem;

          input {
            width: 4em;
            padding: 0.125rem 0.5rem;
            height: 1.75rem;
            border: 1px solid #a0b4bf;
            border-radius: 3px !important;

            &:hover {
              border-width: 2px;
            }
          }
        }
      }

      cep2-unit-form-field {
        .tab-content {
          padding-top: 2em !important;
          border-left: 1px solid #ddd;
        }

        .nav-tabs {
          position: relative;

          .nav-item {
            height: 2.0625rem;
            float: left;
            cursor: pointer;
            padding: 0.3125rem 0.9375rem;

            &.active,
            &:hover {
              border: none;
              color: white;
              background-color: $color-purple-light;
            }

            &.active {
              .unit-help-text {
                display: block;
              }
            }

            .unit-help-text {
              display: none;
              position: absolute;
              top: 2.625rem;
              left: 0.625rem;
            }
          }
        }
      }

      // Invalid fields
      .has-error {
        .input-group-text {
          background-color: transparent;
        }

        .input-group > .input-group-text:last-child {
          background-color: #eee;
        }

        // calendar icons on invalid fields
        .btn, .btn:hover {
          background-color: #dc3545;
          border-color: #dc3545;
        }
      }
    }

    .form-control {
      // Increase form group height to align calendar button with input
      height: $default-input-height;
      border-radius: $default-border-radius;

      &.short-input {
        height: $default-input-height;
      }
    }

    // Width of unit-numbers and calendars must not be 100%
    .input-group-addon {
      width: auto;
    }

    // Search form
    .question-form .unicorn-search .unicorn-search__inner {
      line-height: $default-input-height;
    }

    // Datepicker button and group
    .btn.btn-secondary.calender-btn {
      border: none;
      height: 2rem;
      width: 2.75rem;
      padding: 0 0.375rem 0 0.375rem;
      line-height: 1;

      &:disabled {
        display: none;
      }
    }

    input div.input-group.with-datepicker {
      height: $default-input-height;
    }

    .bs-datepicker-body {
      border: 1px solid #dee2e6;
    }

    .input-group-text {
      border-radius: $default-border-radius;
    }
  }

  .rsp-assessment-bottom-navigation {
    padding-top: 0.3125rem;
  }

  // simple control elements and breadcrumb above paginated questionnaire
  cep-web-breadcrumb-pagination, cep-web-prev-next-pagination {
    cep2-control-element-container {
      a {
        color: $color-purple-dark;
        text-decoration: none;
      }
    }

    a.cep-qa_questionnaire_simple-pagination_next-page-link, a.cep-qa_questionnaire_simple-pagination_previous-page-link {
      border-radius: $default-border-radius;
    }
  }

  // full pagination navigation below paginated questionnaire
  cep-web-full-page-pagination cep2-control-element-container {
    .pagination {
      .page-item:first-child .page-link {
        border-top-left-radius: $default-border-radius;
        border-bottom-left-radius: $default-border-radius;
      }

      .page-item:last-child .page-link {
        border-top-right-radius: $default-border-radius;
        border-bottom-right-radius: $default-border-radius;
      }

      .page-link {
        color: $color-purple-light;

        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(102, 92, 150, 0.25);
        }
      }

      .page-link.disabled, .disabled > .page-link {
        color: var(--bs-pagination-disabled-color);
      }

      .page-link.active, .active > .page-link {
        color: #fff;
        background-color: $color-purple-light;
        // background-color: var(--bs-pagination-active-bg);
        border-color: $color-purple-light;
      }
    }
  }
}
