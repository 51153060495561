@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  $border-color-rgba: rgba(red($border-color), green($border-color), blue($border-color), 0.25);
  $box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 $input-btn-focus-width $border-color-rgba;

  .form-text,
  .col-form-label,
  .radio,
  .checkbox,
  &.radio label,
  &.checkbox label {
    color: $text-color;
  }

  .form-control,
  .checkbox input[type='checkbox'] {
    border-color: $border-color;

    &:focus {
      border-color: darken($border-color, 10%);
      box-shadow: $box-shadow;
    }
  }

  .unicorn-search__inner--focused {
    border-color: darken($border-color, 10%);
    box-shadow: $box-shadow;
  }

  .input-group-text {
    border-color: $border-color;
    background-color: mix($white, $background-color, abs($alert-bg-scale) * 0.8);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @if ($cep-enable-custom-checkbox-radio) {
    .radio,
    .checkbox {
      input[type='radio'],
      input[type='checkbox'] {
        + .key {
          border-color: $border-color;
        }

        &:focus + .key {
          border-color: $border-color;
          box-shadow: $box-shadow;
        }
      }
    }
  }
}
